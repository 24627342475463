import React, { Component } from 'react'
import { connect } from 'react-redux';

class PageWrapper extends Component {
  render() {
    return (
      <>{this.props.children}</>
    )
  }
}

export default connect(null)(PageWrapper);

import React, { Component } from 'react'
import { GOOGLE_MAP_URL } from '../../helpers/constants';
import MapContainer from './MapContainer'

class Map extends Component {
  constructor(props) {
    super(props)
    this.child = React.createRef();
  }

  updateLocation(currentLocation) {
    this.child.current.updateLocation(currentLocation);
  }

  render() {
    return (
      <MapContainer
        ref={this.child}
        googleMapURL={GOOGLE_MAP_URL}
        loadingElement={< div style={{ height: `100%` }} />}
        containerElement={< div style={{ height: `calc(100vh - 180px)` }} />}
        mapElement={< div style={{ height: `100%` }} />}
        {...this.props}
      />
    )
  }
}

export default Map
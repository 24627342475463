import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';

// Pages
import Register from './pages/Register';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Geofence from './pages/Geofence';
import LocationHistory from './pages/LocationHistory';
import Reports from './pages/Reports';
import DeviceList from './pages/Devices/List';
import DeviceUpdate from './pages/Devices/Update';
import DeviceView from './pages/Devices/View';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

class Routes extends Component {

  render() {
    return (
      <BrowserRouter>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/register" component={Register} />
          <PrivateRoute exact path="/devices" component={DeviceList} />
          <PrivateRoute exact path="/device/update/:id" component={DeviceUpdate} />
          <PrivateRoute exact path="/device/view/:id" component={DeviceView} />
          <PrivateRoute exact path="/geofence" component={Geofence} />
          <PrivateRoute exact path="/history" component={LocationHistory} />
          <PrivateRoute exact path="/reports" component={Reports} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Routes;
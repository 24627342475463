import { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { deviceDetails } from "../../actions/device";
import Base from '../../components/layout/Base';
import { APIURL } from "../../actions/config";
import trackerIcon from '../../assets/images/trackerIcon.png'

class View extends Component {

   componentDidMount() {
      const { id } = this.props.match.params;
      this.props.deviceDetails(id);
   }

   render() {
      const { device } = this.props;
      return (
         <Base title="Device" page="devices">
            {device ?
               <div className="card">
                  <div className="card-body text-center">
                     <div className="profile-pic mb-3 mt-3">
                        <img src={device.icon ? `${APIURL}/uploads/${device.icon}` : trackerIcon} width="100" className="rounded-circle" alt="user" />
                        <h3 className="mt-3 mb-0">{device.name}</h3>
                     </div>
                  </div>
                  <div class="table-responsive">
                     <table class="table">
                        <tbody>
                           <tr>
                              <th scope="col">Alias</th>
                              <td>{device.alias}</td>
                           </tr>
                           <tr>
                              <th scope="col">EUI</th>
                              <td>{device.eui}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  {/* <div className="p-4 border-top mt-3">
                        <div className="row text-center">
                            <div className="col-6 border-right">
                                <a href="#" className="link d-flex align-items-center justify-content-center font-weight-medium"><i className="mdi mdi-message font-20 mr-1"></i>Message</a>
                            </div>
                            <div className="col-6">
                                <a href="#" className="link d-flex align-items-center justify-content-center font-weight-medium"><i className="mdi mdi-developer-board font-20 mr-1"></i>Portfolio</a>
                            </div>
                        </div>
                    </div> */}
               </div>
               : null}
         </Base>
      )
   }
}

View.propTypes = {
   deviceDetails: PropTypes.func.isRequired,
   device: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
   device: state.device.details
})

export default connect(mapStateToProps, { deviceDetails })(View);
import { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Redirect } from "react-router";
import { SubmissionError } from 'redux-form';
import { setAlert } from '../../actions/alert';
import { resetPassword } from '../../actions/auth';
import Alert from '../../components/layout/Alert';
import logo from '../../assets/images/logo.png'
import Form from "./Form";
import bgImg from "../../Asset/images/bg_img.png";

class ResetPassword extends Component {

   formHandler = (formData) => {
      const { otp, newPassword, cpassword } = formData;
      if (Object.keys(formData).length) {
         if (!otp) {
            throw new SubmissionError({
               otp: `OTP is required`,
               _error: 'Failed to reset password'
            })
         }
         if (!newPassword) {
            throw new SubmissionError({
               newPassword: `New password is required`,
               _error: 'Failed to reset password'
            })
         }
         if (!cpassword) {
            throw new SubmissionError({
               cpassword: `Confirm password is required`,
               _error: 'Failed to reset password'
            })
         }
         if (newPassword !== cpassword) {
            throw new SubmissionError({
               cpassword: `New password doesn't match cofirm password`,
               _error: 'Failed to reset password'
            })
         }
         this.props.resetPassword({ otp, newPassword });
      } else {
         throw new SubmissionError({
            email: 'OTP is required',
            _error: 'Failed to reset password'
         })
      }
   }

   render() {
      if (this.props.isAuthenticated) {
         return <Redirect to="/dashboard" />;
      }
      return (
          <div className="body-img" style={{ backgroundImage: `url(${bgImg})` }}>
             <div className="sat-section">
                <div className="container">
                   <div className="row justify-content-center align-items-center">
                      <div className="col-lg-5 col-md-4">
                         <div className="sat-logo"><img src={logo} alt="logo" /></div>
                      </div>
                      <div className="col-lg-7 col-md-8">
                         <div className="right-white-box">
                            <Alert />
                            <Form resetPassword={this.formHandler} />
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
      )
   }
}

ResetPassword.propTypes = {
   setAlert: PropTypes.func.isRequired,
};

export default connect(null, { setAlert, resetPassword })(ResetPassword);
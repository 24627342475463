import React, { Component } from 'react'
import { connect } from 'react-redux';
import MainWrapper from './MainWrapper';
import PageWrapper from './PageWrapper';
import Container from './Container';
import Topbar from '../Navigation/Topbar';
import Sidebar from '../Navigation/Sidebar';
import Footer from './Footer';
import Loader from '../Loader';
import Breadcrumb from '../Breadcrumb';

class Base extends Component {

  render() {
    return (
      <MainWrapper>
          <nav className="navbar header-top fixed-top navbar-expand-lg">
              <Topbar title={this.props.title} />
              <Sidebar page={this.props.page} devicesMenu={this.props.devices}/>
          </nav>
        <PageWrapper>
          {/* {this.props.devices ? <Breadcrumb /> : null} */}
          {this.props.loader ? <Loader /> : null}
          <Container>
            {this.props.children}
          </Container>
          <Footer />
        </PageWrapper>
      </MainWrapper>
    )
  }
}

export default connect(null)(Base);

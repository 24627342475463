import axios from "axios";
import { setAlert } from "./alert";
import {
   LOAD_WIDGETS,
   LOAD_LOCATION,
   SET_LOCATION,
   SET_DATE
} from "./types";
import { APIURL } from "./config";
import moment from "moment";

// Widgets
export const getWidgets = (data) => async (dispatch) => {
   const token = localStorage.getItem("token")
   const config = {
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${token}`
      },
   };
   const body = JSON.stringify(data);
   try {
      const res = await axios.post(`${APIURL}/dashboard/widgets`, body, config);
      dispatch({
         type: LOAD_WIDGETS,
         payload: res.data,
      });
   } catch (err) {
      const error = err.response ? err.response.data.msg : err.message;
      if (error) {
         dispatch(setAlert(error, "danger"));
      }
   }
};

//Recent Location
export const recentLocation = (data) => async (dispatch) => {
   const token = localStorage.getItem("token")
   const config = {
      headers: {
         "Content-Type": "application/json",
         "Authorization": `Bearer ${token}`
      },
   };
   data.date = new Date().getTime();
   const body = JSON.stringify(data);
   try {
      const res = await axios.post(`${APIURL}/location/recent`, body, config);
      dispatch({
         type: LOAD_LOCATION,
         payload: res.data,
      });
      if (res.data.locations.length) {
         function sortFunction(a,b){
            var dateA = new Date(a.date).getTime();
            var dateB = new Date(b.date).getTime();
            return dateA > dateB ? 1 : -1;
         };

         res.data.locations.sort(sortFunction);
         const [currentLocation] = res.data.locations.slice(-1);
         const locations = res.data.locations;
         let rsMax = res.data.locations.length - 1;
         let rsValue = res.data.locations.length - 1;
         if (res.data.locations.length == 1) {
            rsMax = res.data.locations.length;
            rsValue = res.data.locations.length;
         }
         const date = new Date(currentLocation.createdAt);
         const minDate = new Date(res.data.firstEntry.createdAt)
         const maxDate = new Date(currentLocation.createdAt)
         dispatch({
            type: SET_LOCATION,
            payload: { currentLocation, date, rsMax, rsValue,locations,minDate,maxDate }
         })
      }
   } catch (err) {
      const error = err.response ? err.response.data.msg : err.message;
      if (error) {
         dispatch(setAlert(error, "danger"));
      }
   }
};


//Get Location
export const getLocation = (data) => async (dispatch) => {
   const config = {
      headers: {
         "Content-Type": "application/json",
      },
   };
   const body = JSON.stringify(data);
   try {
      const res = await axios.post(`${APIURL}/location`, body, config);
      dispatch({
         type: LOAD_LOCATION,
         payload: res.data,
      });
      if (res.data.length) {
         function sortFunction(a,b){
            var dateA = new Date(a.date).getTime();
            var dateB = new Date(b.date).getTime();
            return dateA > dateB ? 1 : -1;
         };

        res.data.sort(sortFunction);
         let rsMax = res.data.length - 1;
         let rsValue = res.data.length - 1;
         if (res.data.length == 1) {
            rsMax = res.data.length;
            rsValue = res.data.length;
         }
         let [currentLocation] = res.data.slice(-1);
         if (data.type !== "prev") {
            rsValue = 0;
            [currentLocation] = res.data.slice(0);
         }
         const date = new Date(currentLocation.createdAt);
         dispatch({
            type: SET_LOCATION,
            payload: { currentLocation,date, rsMax, rsValue }
         })
      } else {
         dispatch({
            type: SET_LOCATION,
            payload: { rsMax: null, rsValue: null }
         })
      }
   } catch (err) {
      const error = err.response ? err.response.data.msg : err.message;
      if (error) {
         dispatch(setAlert(error, "danger"));
      }
   }
};

//Update Location
export const updateLocation = data => async dispatch => {
   dispatch({
      type: SET_LOCATION,
      payload: data
   })
}

//Set Date
export const setDate = (date) => async (dispatch) => {
   dispatch({
      type: SET_DATE,
      payload: date,
   });
};
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { renderWithoutField } from '../../components/reduxForm/Fields';
import "react-widgets/styles.css";

let Form = props => {

   const { handleSubmit, pristine, submitting, submitLogin } = props;
   return (
       <form id="logform" onSubmit={handleSubmit(submitLogin)}>
          <h2 className="h2">Sign in</h2>
          <p className="mb-4">Enter your email address and password to access tracking portal.</p>
          <div className="form-group">
              <Field type="text" name="email" component={renderWithoutField} label="Email address" />
             <button type="button" className="field-icon hidediv" disabled={pristine || submitting} ><span className="fa fa-arrow-right"></span>
             </button>
          </div>
          <div className="form-group next-field" id="" style={{display:"none"}}>
              <Field type="password" name="password" component={renderWithoutField} label="Enter password" />
             <button type="submit" disabled={pristine || submitting} className="field-icon"><span className="fa fa-arrow-right"></span></button>
          </div>
          <div className="forgot-pass text-center">
             <Link id="forgotPwd" to="/forgotpassword"><span>Forgot Password?</span></Link>
          </div>
          <div className="sat-ls-btn text-center">
             <a href="#_" id="signup">Don't have an account? <span className="red-color">Sign Up</span></a>
          </div>
       </form>
   )
}

Form = reduxForm({
   form: 'login',
})(Form);

export default connect(null, {})(Form);

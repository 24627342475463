import { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Redirect } from "react-router";
import { SubmissionError } from 'redux-form';
import { setAlert } from '../../actions/alert';
import { sendOTP } from '../../actions/auth';
import Alert from '../../components/layout/Alert';
import logo from '../../assets/images/logo.png'
import bgImg from '../../Asset/images/bg_img.png'
import Form from './Form';

class ForgotPassword extends Component {

   formHandler = (formData) => {
      const { email } = formData;
      if (Object.keys(formData).length) {
         if (!email) {
            throw new SubmissionError({
               email: `Email is required`,
               _error: 'Failed to send OTP'
            })
         }
         this.props.sendOTP(email);
         this.props.history.push('/resetpassword');
      } else {
         throw new SubmissionError({
            email: 'Email is required',
            _error: 'Failed to send OTP'
         })
      }
   }

   render() {
      if (this.props.isAuthenticated) {
         return <Redirect to="/dashboard" />;
      }
      return (
          <div className="body-img" style={{ backgroundImage: `url(${bgImg})` }}>
             <div className="sat-section">
                <div className="container">
                   <div className="row justify-content-center align-items-center">
                      <div className="col-lg-5 col-md-4">
                         <div className="sat-logo"><img src={logo} alt="logo" /></div>
                      </div>
                      <div className="col-lg-7 col-md-8">
                         <div className="right-white-box">
                            <Alert />
                            <Form submitForgot={this.formHandler} />
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
      )
   }
}

ForgotPassword.propTypes = {
   setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
   otpsent: state.auth.otpsent
});

export default connect(mapStateToProps, { setAlert, sendOTP })(ForgotPassword);
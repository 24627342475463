import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setDevice } from "../../../actions/device";
import { recentLocation } from "../../../actions/dashboard";
import { toggleSidebar } from '../../../actions/sidebar';
import logo from '../../../assets/images/logo.png'

import avatar from '../../../assets/images/users/profile-pic.jpg';
import brandLogo from '../../../assets/images/brand-logo.png';
import logoIcon from '../../../assets/images/logo-icon.png';

class Topbar extends Component {

  toggleMenu = e => {
    this.props.toggleSidebar(!this.props.status);
  }

  render() {
    return (
        <a className="navbar-brand p-0" href="#"><img src={logo} alt="logo"/></a>
    )
  }
}

Topbar.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});
export default connect(mapStateToProps, { toggleSidebar })(Topbar);
import { Component } from "react";
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-v.png'
import locatoinIcon from '../../assets/images/big/icon.png'

class Register extends Component {

  render() {
    return (
      <div class="main-wrapper">
        {/* <!-- Preloader - style you can find in spinners.css --> */}
        {/* <div class="preloader">
          <div class="lds-ripple">
            <div class="lds-pos"></div>
            <div class="lds-pos"></div>
          </div>
        </div> */}
        {/* <!-- Preloader - style you can find in spinners.css --> */}
        {/* <!-- Login box.scss --> */}
        <div class="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative"
          style={{ background: "url(../assets/images/big/auth-bg.jpg) no-repeat center center" }}>
          <div class="auth-box row text-center">
            <div class="col-lg-7 col-md-5 modal-bg-img" style={{ "background-image": `url(${logo})` }}>
            </div>
            <div class="col-lg-5 col-md-7 bg-white">
              <div class="p-3">
                <img src={locatoinIcon} alt="wrapkit" />
                <h2 class="mt-3 text-center">Sign Up</h2>
                <form class="mt-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input class="form-control" type="text" placeholder="your name" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input class="form-control" type="email" placeholder="email address" />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input class="form-control" type="password" placeholder="password" />
                      </div>
                    </div>
                    <div class="col-lg-12 text-center">
                      <button type="submit" class="btn btn-block btn-dark">Sign Up</button>
                    </div>
                    <div class="col-lg-12 text-center mt-5">
                      Already have an account? <Link className="text-danger" to="/">Sign In</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Login box.scss --> */}
      </div >
    )
  }
}

export default Register;
import { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { SubmissionError } from 'redux-form';
import { deviceDetails, updateDevice } from "../../actions/device";
import Base from '../../components/layout/Base';
import Alert from '../../components/layout/Alert';
import Form from "./Form";

class Update extends Component {

   componentDidMount() {
      const { id } = this.props.match.params;
      this.props.deviceDetails(id);
   }

   formHandler = formData => {
      if (Object.keys(formData).length) {
         ['name', 'alias', 'eui'].forEach(field => {
            if (!formData[field]) {
               throw new SubmissionError({
                  [field]: `This field is required`,
                  _error: 'Failed to add user'
               })
            }
         });
         this.props.updateDevice(formData);
      } else {
         throw new SubmissionError({
            Alias: 'Alias is required',
            _error: 'Failed to add user'
         })
      }
   }
   render() {
      return (
         <Base title="Device" page="devices">
            <Alert />
            <div className="row">
               <div className="col-12">
                  <div className="card">
                     <div className="card-body">
                        <h4 className="card-title">Update Device</h4>
                        <Form submitDevice={this.formHandler} />
                     </div>
                  </div>
               </div>
            </div>
         </Base>
      )
   }
}

Update.propTypes = {
   deviceDetails: PropTypes.func.isRequired,
   updateDevice: PropTypes.func.isRequired,
   device: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
   device: state.device.details
})

export default connect(mapStateToProps, { deviceDetails, updateDevice })(Update);
import { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { listDevices, setDevice } from '../../actions/device';
import { getWidgets, recentLocation } from '../../actions/dashboard';
import './style.css';

class Breadcrumb extends Component {

  // componentDidMount() {
  //   this.props.listDevices()
  // }

  deviceHandler = event => {
    const deviceId = event.target.value
    const { devices } = this.props;
    const [currentDevice] = devices.filter(item => item.id === deviceId);
    const icon = currentDevice.icon ? currentDevice.icon : null;
    this.props.setDevice({ deviceId, icon });
    // this.props.getWidgets({ deviceId });
    this.props.recentLocation({ date: new Date().getTime(), deviceId })
  }

  render() {
    return (
      <div className="page-breadcrumb mobile-visible">
        <div className="row">
          <div className="col-12 align-self-center">
            <div className="customize-input">
              <select onChange={this.deviceHandler} className="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius">
                {this.props.devices && this.props.devices.map((device, index) => {
                  return <option key={index} value={device.id} >{device.alias ? device.alias : device.name}</option>
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Breadcrumb.propTypes = {
  listDevices: PropTypes.func.isRequired,
  setDevice: PropTypes.func.isRequired,
  getWidgets: PropTypes.func.isRequired,
  recentLocation: PropTypes.func.isRequired,
  devices: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  devices: state.device.list
})

export default connect(mapStateToProps, { listDevices, setDevice, getWidgets, recentLocation })(Breadcrumb);
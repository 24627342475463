import Multiselect from "react-widgets/Multiselect";
import "react-widgets/styles.css";
import './style.css';



export const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="form-group">
        <label>{label}</label>
        <input {...input} className="form-control" placeholder={label} type={type} />
        {touched && error && <span className="error">{error}</span>}
    </div>
)

export const renderWithoutField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="form-group">
        <input {...input} className="form-control" placeholder={label} type={type} />
        {touched && error && <span className="error">{error}</span>}
    </div>
)

export const renderWithValue = ({ input, label, type, readOnly, meta: { touched, error } }) => (
    <div className="form-group">
        <input {...input} className="form-control" value={label} type={type} readOnly={readOnly} />
        {touched && error && <span className="error">{error}</span>}
    </div>
)

export const renderFile = ({ input, label, type, meta: { touched, error } }) => (
    <div className="form-group">
        <label>{label}</label>
        <input {...input} className="form-control-file" placeholder={label} type={type} />
        {touched && error && <span className="error">{error}</span>}
    </div>
)

export const renderMultiselect = ({ input, data, label, defaultValue, dataKey, textField, meta: { touched, error } }) => (

    <div className="form-group">
        <label>{label}</label>
        <Multiselect
            {...input}
            onBlur={() => input.onBlur()}
            value={input.value || []}
            data={data}
            dataKey={dataKey}
            textField={textField}
            defaultValue={defaultValue}
        />
        {touched && error && <span className="error">{error}</span>}
    </div>
)
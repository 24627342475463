import axios from "axios";
import { setAlert } from "./alert";
import { LIST_DEVICE, DEVICE_DETAILS, SET_DEVICE } from "./types";
import { recentLocation } from "./dashboard";
import { APIURL } from './config';


export const listDevices = () => async dispatch => {
    const token = localStorage.getItem("token")
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    };
    try {
        const res = await axios.get(`${APIURL}/devices`, config);
        dispatch({
            type: LIST_DEVICE,
            payload: res.data,
        });
        if (res.data && res.data.length) {
            const deviceId = res.data[0].id;
            const icon = res.data[0].icon ? res.data[0].icon : null;
            dispatch({
                type: SET_DEVICE,
                payload: { deviceId, icon }
            });
            dispatch(recentLocation({ date: new Date(), deviceId }));
        }
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
    }
}

export const deviceDetails = (id) => async dispatch => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    try {
        const res = await axios.get(`${APIURL}/device/${id}`, config);

        dispatch({
            type: DEVICE_DETAILS,
            payload: res.data,
        });
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
        }
    }
}

//Update Device
export const updateDevice = (data) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    }
    const body = JSON.stringify(data);
    try {
        const res = await axios.put(`${APIURL}/device/update`, body, config);
        dispatch(setAlert(res.data.msg, "success"))
    } catch (err) {
        const error = err.response.data.msg;
        dispatch(setAlert(error, "danger"));
    }
};

//Update Device Icon
export const updateDeviceIcon = formData => async (dispatch) => {

    const config = {
        headers: { "content-type": "multipart/form-data" }
    };
    try {
        const res = await axios.put(`${APIURL}/device/updateIcon`, formData, config);
        dispatch(setAlert(res.data.msg, "success"))
    } catch (err) {
        const error = err.response.data.msg;
        dispatch(setAlert(error, "danger"));
    }
}

export const setDevice = data => async dispatch => {
    dispatch({
        type: SET_DEVICE,
        payload: data,
    });
}
import { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Redirect } from "react-router";
import { SubmissionError } from 'redux-form';
import { setAlert } from '../../actions/alert';
import { login } from '../../actions/auth';
import Alert from '../../components/layout/Alert';
import logo from '../../assets/images/logo.png'
import bgImg from '../../Asset/images/bg_img.png'
import locatoinIcon from '../../assets/images/big/icon.png'
import Form from "./Form";
import $ from "jquery";

class Login extends Component {

  formHandler = (formData) => {
    const { email, password } = formData;
    if (Object.keys(formData).length) {
      if (!email) {
        throw new SubmissionError({
          email: `Email is required`,
          _error: 'Failed to add user'
        })
      }
      if (!password) {
        throw new SubmissionError({
          password: `Password is required`,
          _error: 'Failed to add user'
        })
      }
      this.props.login({ email, password });
    } else {
      throw new SubmissionError({
        email: 'Email is required',
        _error: 'Failed to add user'
      })
    }
  }

  componentDidMount() {
    $('.hidediv').on('click', function () {
      $('.next-field').show();
      $('.hidediv').hide();
    })
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }
    return (
        <div className="body-img" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="sat-section">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-5 col-md-4">
                <div className="sat-logo"><img src={logo} alt="logo" /></div>
              </div>
              <div className="col-lg-7 col-md-8">
                <div className="right-white-box">
                  <Form submitLogin={this.formHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
  }
}

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, login })(Login);
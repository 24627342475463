import {
    LOAD_WIDGETS,
    LOAD_LOCATION,
    SET_LOCATION,
    SET_DATE
} from "../actions/types";

const initialState = {
    widgets: [],
    currentLocation: {},
    locations: [],
    date: new Date(),
    rsMin: 0,
    rsSteps: 1,
    rsMax: 10,
    rsValue: 0,
    minDate:new Date(),
    maxDate: new Date()
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOAD_WIDGETS:
            return {
                ...state,
                widgets: payload,
            };
        case LOAD_LOCATION:
            return {
                ...state,
                locations: payload
            };
        case SET_LOCATION:
            return {
                ...state,
                ...payload,
            };
        case SET_DATE:
            return {
                ...state,
                date: payload
            }
        default:
            return state;
    }
}

import { LIST_DEVICE, DEVICE_DETAILS, SET_DEVICE } from "../actions/types";

const initialState = {
    list: [],
    details: [],
    deviceId: null,
    icon: null
}

export default function (state = initialState, action) {

    const { type, payload } = action;
    switch (type) {
        case LIST_DEVICE:
            return {
                ...state,
                list: payload
            };
        case DEVICE_DETAILS:
            return {
                ...state,
                details: payload
            }
        case SET_DEVICE:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
    }
}
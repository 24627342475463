import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { logout } from '../../../actions/auth';
import { toggleSidebar } from '../../../actions/sidebar';
import brandLogo from '../../../assets/images/brand-logo.png';
import avatarImg from '../../../Asset/images/avatars_img.png';
import dashboardImg from '../../../Asset/images/dashboard_icon.svg';
import deviceIcon from '../../../Asset/images/divices_icon.svg';
import geoFenceIcon from '../../../Asset/images/geo_fence_icon.svg';
import historyIcon from '../../../Asset/images/history_icon.svg';
import reportIcon from '../../../Asset/images/reports_icon.svg';
import logoutIcon from '../../../Asset/images/logout_icon.svg';
import {setDevice} from "../../../actions/device";
import {recentLocation} from "../../../actions/dashboard";
import logoutIconActive from "../../../Asset/images/logout_icon_active.svg";
import $ from "jquery";
import {Modal} from 'react-bootstrap';

class Sidebar extends Component {

  deviceHandler = event => {
    const deviceId = event.target.value
    const { devices } = this.props;
    const [currentDevice] = devices.filter(item => item.id === deviceId);
    const icon = currentDevice.icon ? currentDevice.icon : null;
    this.props.setDevice({ deviceId, icon });
    // this.props.getWidgets({ deviceId });
    this.props.recentLocation({ date: new Date().getTime(), deviceId })
  }

  state = {
    logoutShow:false
  }

  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  }
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  }
  showLogoutPopup = () => {
    this.setState({
      logoutShow:true
    })
  }
  handleClose = () => {
    this.setState({
      logoutShow:false
    })
  }

  hideSidebar = () => {
    this.props.toggleSidebar(false);
  }

  render() {
    return (
        <div className="navbar-collapse" id="navbarText">
          <ul className="navbar-nav side-nav">
            <li className="nav-item btb">
              <div className="account-img menu-icon"><img src={avatarImg} alt="account img" />
              </div>
              <a className="nav-link" href="#">Account
              </a>
            </li>
            <li className="nav-item">
                <Link className={`nav-link ${this.props.page === "dashboard" ? "active" : ""
                }`} to="/dashboard" aria-expanded="false">
                <div className="menu-icon"><img src={dashboardImg}
                                                alt="Dashboard Icon" /></div>
                <span>Dashboard</span>
                </Link>
            </li>

            <li className="nav-item">
              <Link className={`nav-link ${this.props.page === "devices" ? "active" : ""
              }`} to="/devices">
                <div className="menu-icon"><img src={deviceIcon} alt="divices Icon" /></div>
                <span>Devices</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.props.page === "geofence" ? "active" : ""
              }`} to="/geofence">
                <div className="menu-icon"><img src={geoFenceIcon} alt="Geo-fence" /></div>
                <span>Geo-fence</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.props.page === "history" ? "active" : ""
              }`} to="/history">
                <div className="menu-icon"><img src={historyIcon} alt="History" /></div>
                <span>History</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${this.props.page === "reports" ? "active" : ""
              }`} to="/reports">
                <div className="menu-icon"><img src={reportIcon} alt="Reports" /></div>
                <span>Reports</span>
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav sidenavtoggler">
            <li className="nav-item logout">
              <a className="nav-link" href="#" onClick={this.showLogoutPopup}>
                <div className="menu-icon"><img src={logoutIcon} alt="Logout" /></div>
                <span>Logout</span>
              </a>
            </li>
          </ul>
          <span className="mobile-menu-icon" onClick={this.openNav}>&#9776;</span>
            {this.props.devicesMenu ?
                <ul className="navbar-nav ml-md-auto d-md-flex align-items-center device">
                <form class="form-horizontal">
                  <h6 className="mr-2 mb-0">Device</h6>
                  <select onChange={this.deviceHandler} className="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius">
                    {this.props.devices && this.props.devices.map((device, index) => {
                      return <option key={index} value={device.id} >{device.alias ? device.alias : device.name}</option>
                    })}
                  </select>
                </form>
                </ul>
            : null}
          <Modal show={this.state.logoutShow} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <button type="button" className="close" data-dismiss="modal"><i className="fa fa-close"></i></button>
            </Modal.Header>
            <Modal.Body>
              <div className="square-box m-auto">
                <div className="logout-icon"><img src={logoutIconActive} alt="logout_icon" /></div>
              </div>
              <p className="mt-3 mb-4">Are you sure want to logout?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn border-btn" data-dismiss="modal">Cancel</button>
                <button type="button" className="btn red-btn" onClick={this.props.logout}>Logout</button>
            </Modal.Footer>
          </Modal>
          <div id="mySidenav" className="mobilesidenav">
            <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}>&times;</a>
            <a href="/" className="mobile-logo"><img src={brandLogo} alt="logo"/></a>
            <ul className="navbar-nav">
              <li className="nav-item btb">
                <a className="nav-link" href="#">
                  <div className="account-img menu-icon"><img src={avatarImg} alt="account img"/></div>
                  <span>Account</span>
                </a>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${this.props.page === "dashboard" ? "active" : ""
                }`} to="/dashboard">
                  <div className="menu-icon"><img src={dashboardImg} alt="Dashboard Icon"/></div>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${this.props.page === "devices" ? "active" : ""
                }`} to="/devices">
                  <div className="menu-icon"><img src={deviceIcon} alt="divices Icon"/></div>
                  <span>Devices</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${this.props.page === "geofence" ? "active" : ""
                }`} to="/geofence">
                  <div className="menu-icon"><img src={geoFenceIcon} alt="Geo-fence" /></div>
                  <span>Geo-fence</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${this.props.page === "history" ? "active" : ""
                }`} to="/history">
                  <div className="menu-icon"><img src={historyIcon} alt="History" /></div>
                  <span>History</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${this.props.page === "reports" ? "active" : ""
                }`} to="/reports">
                  <div className="menu-icon"><img src={reportIcon} alt="Reports" /></div>
                  <span>Reports</span>
                </Link>
              </li>
              <li className="nav-item logout">
                <a className="nav-link" href="#" onClick={this.showLogoutPopup}>
                  <div className="menu-icon"><img src={logoutIcon} alt="Logout" /></div>
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
    )
  }
}

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setDevice: PropTypes.func.isRequired,
  recentLocation: PropTypes.func.isRequired,
  devices: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  devices: state.device.list,
  status: state.sidebar.status
});
export default connect(mapStateToProps, { logout, setDevice, recentLocation, toggleSidebar })(Sidebar);
import { Component } from "react";

class Loader extends Component {

  render() {
    return (
      //Preloader - style you can find in spinners.css
      <div className="preloader">
        <div className="lds-ripple">
          <div className="lds-pos"></div>
          <div className="lds-pos"></div>
        </div>
      </div>
    )
  }
}

export default Loader;
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const LIST_DEVICE = "LIST_DEVICE";
export const DEVICE_DETAILS = "DEVICE_DETAILS";
export const SET_DEVICE = "SET_DEVICE";
export const LOAD_WIDGETS = "LOAD_WIDGETS";
export const LOAD_LOCATION = "LOAD_LOCATION";
export const SET_LOCATION = "SET_LOCATION";
export const SET_DATE = "SET_DATE";
export const SIDEBAR = "SIDEBAR";
export const OTP_SENT = "OTP_SENT";
export const PASSWORD_RESET = "PASSWORD_RESET";
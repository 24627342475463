import { Component } from "react";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Info extends Component {

  render() {
    return (
      <div className="card border-right">
        <div className="card-body">
          <div className="d-flex d-lg-flex d-md-block align-items-center">
            <div>
              <div className="d-inline-flex align-items-center">
                <h3 className="text-dark mb-1 font-weight-medium">{this.props.title}</h3>
                {this.props.value ?
                  <span
                    className={`badge bg-${this.props.color} font-16 text-white font-weight-medium badge-pill ml-2 d-lg-block d-md-none`}>{this.props.value}</span>
                  : null}
              </div>
              <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate"><Moment fromNow>{this.props.time}</Moment></h6>
            </div>
            <div className="ml-auto mt-md-3 mt-lg-0">
              <span className="opacity-7 text-muted"><FontAwesomeIcon icon={['fas', this.props.icon]} size="2x" className={`text-${this.props.color}`} /></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Info;
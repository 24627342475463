import axios from "axios";
import { setAlert } from "./alert";
import {
   LOGIN_SUCCESS,
   LOGIN_FAIL,
   USER_LOADED,
   AUTH_ERROR,
   OTP_SENT,
   PASSWORD_RESET,
   LOGOUT,
} from "./types";
import { APIURL } from "./config";
import setAuthToken from "../utils/setAuthToken";
import toast, { Toaster } from 'react-hot-toast';


// Load User
export const loadUser = () => async (dispatch) => {
   if (localStorage.token) {
      setAuthToken(localStorage.token);
   }
   try {
      const res = await axios.get(`${APIURL}/auth`);
      dispatch({
         type: USER_LOADED,
         payload: res.data,
      });
   } catch (error) {
      dispatch({
         type: AUTH_ERROR,
      });
   }
};

//Login User

export const login =
   ({ email, password }) =>
      async (dispatch) => {
         const config = {
            headers: {
               "Content-Type": "application/json",
            },
         };
         email = email.toLowerCase();

         const body = JSON.stringify({ email, password });

         try {
            const res = await axios.post(`${APIURL}/login`, body, config);
            setAuthToken(res.data.token);
            dispatch({
               type: LOGIN_SUCCESS,
               payload: res.data,
            });
            dispatch(loadUser());
         } catch (err) {
            const error = err.response ? err.response.data.msg : err.message;
            if (error) {
               dispatch(setAlert(error, "danger"));
            }
            toast.error("Email or password is wrong!")
            dispatch({
               type: LOGIN_FAIL,
            });
         }
      };

export const sendOTP = (email) => async dispatch => {
   const config = {
      headers: {
         "Content-Type": "application/json",
      },
   };

   const body = JSON.stringify({ email });

   try {
      const res = await axios.post(`${APIURL}/sendOTP`, body, config);
      dispatch({
         type: OTP_SENT,
         payload: true,
      });
   } catch (err) {
      const error = err.response ? err.response.data.msg : err.message;
      if (error) {
         dispatch(setAlert(error, "danger"));
      }
   }
};

export const resetPassword = ({ otp, newPassword }) => async dispatch => {
   const config = {
      headers: {
         "Content-Type": "application/json",
      },
   };

   const body = JSON.stringify({ otp, newPassword });

   try {
      const res = await axios.post(`${APIURL}/resetPassword`, body, config);
      dispatch(setAlert(res.data.msg, "success"));
   } catch (err) {
      const error = err.response ? err.response.data.msg : err.message;
      if (error) {
         dispatch(setAlert(error, "danger"));
      }
   }
};

// Logout/Clear Profile
export const logout = () => (dispatch) => {
   dispatch({
      type: LOGOUT,
   });
};

import React from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// import { MAP_SETTINGS } from '../../helpers/constants'
import { APIURL } from "../../actions/config";
import trackerIcon from '../../assets/images/trackerIcon.png'
// import carIcon from '../../assets/img/carIcon.png'

// const {
//   MARKER_SIZE,
//   DEFAULT_ZOOM,
//   DEFAULT_CENTER,
//   DEFAULT_MAP_OPTIONS,
// } = MAP_SETTINGS


class Map extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     moveMarker: false,
  //     defaultCenter: { lat: props.location.lat, lng: props.location.lon },
  //     markerPosition: { lat: props.location.lat, lng: props.location.lon },
  //   };
  // }

  // updateLocation = (currentLocation) => {
  //   const markerPosition = { lat: currentLocation.lat, lng: currentLocation.lon }
  //   this.setState({ markerPosition })
  // }

  // prevLocation = () => {
  //   let { ci, locationHistory } = this.state;
  //   ci++;
  //   if (locationHistory[ci]) {
  //     const markerPosition = { lat: locationHistory[ci].lat, lng: locationHistory[ci].lon }
  //     this.setState({ ci, markerPosition })
  //     // this.transition(locationHistory[ci], this.props.location)
  //   }
  // }

  // nextLocation = () => {
  //   let { ci, locationHistory } = this.state;
  //   ci--;
  //   if (locationHistory[ci]) {
  //     const markerPosition = { lat: locationHistory[ci].lat, lng: locationHistory[ci].lon }
  //     this.setState({ ci, markerPosition })
  //     // this.transition(locationHistory[ci], this.props.location)
  //   }
  // }

  // transition = (newLocation, location) => {
  //   const deltaLat = (newLocation.lat - location.lat) / this.state.numDeltas;
  //   const deltaLng = (newLocation.lon - location.lon) / this.state.numDeltas;
  //   this.moveMarker(deltaLat, deltaLng, 0);
  // }

  // moveMarker = (deltaLat, deltaLng, i) => {
  //   this.setState(prevState => {
  //     return {
  //       markerPosition: { lat: prevState.markerPosition.lat + deltaLat, lng: prevState.markerPosition.lng + deltaLng }
  //     }
  //   })
  //   if (i != this.state.numDeltas) {
  //     i++;
  //     setTimeout(() => { this.moveMarker(deltaLat, deltaLng, i) }, this.state.delay);
  //   }
  // }

  Map = withScriptjs(
    withGoogleMap(props => (
      <GoogleMap
        defaultZoom={15}
        center={{ lat: this.props.currentLocation.lat, lng: this.props.currentLocation.lon }}
        onClick={props.onClick}
      >
        {props.children}
      </GoogleMap>
    ))
  );

  render() {
    return (
      <this.Map {...this.props}>
        {this.props.currentLocation ?
          <Marker
            position={{ lat: this.props.currentLocation.lat, lng: this.props.currentLocation.lon }}
            icon={{ url: this.props.icon ? `${APIURL}/uploads/icons/${this.props.icon}` : trackerIcon }} />
          : null
        }
      </this.Map>
    );
  }
}

Map.propTypes = {
  currentLocation: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  currentLocation: state.dashboard.currentLocation,
  icon: state.device.icon
})
export default connect(mapStateToProps)(Map);
import { Component } from "react";
import Base from "../../components/layout/Base";
import geoIcon from '../../Asset/images/geo_fence_icon.svg'
import imgIcon from '../../Asset/images/img.svg'

class Geofence extends Component {

  render() {
    return (
      <Base title="Geo-Fence" page="geofence">
        <div className="main-content">
          <div className="main-content-wrapper d-flex justify-content-center align-items-center position-relative">
            <div className="coming-soon text-center">
              <div className="comingsoon-circle m-auto">
                <div className="circle-inner">
                  <img src={geoIcon} alt="icon" />
                </div>
              </div>
              <h5 className="red-color text-center">Coming soon</h5>
            </div>
            <div className="soon-img"><img src={imgIcon} alt="" /></div>
          </div>
        </div>
      </Base>
    )
  }
}

export default Geofence;
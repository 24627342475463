import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import DatePicker from 'react-date-picker';
import moment from 'moment';
import Moment from "react-moment";
import goLiveIcon from "../../Asset/images/go_live_icon.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getWidgets, recentLocation, getLocation, updateLocation, setDate } from '../../actions/dashboard';
import { listDevices } from '../../actions/device';
//Components
import Base from "../../components/layout/Base";
import CardInfo from '../../components/Card/Info';
import Map from '../../components/Map';
import './style.css';
import $ from "jquery";

class Dashboard extends Component {

   componentDidMount() {
      this.props.listDevices();
   }

   sliderPosition = () => {
      let slider = $('#month-price')
      let val = slider.val();
      let width = slider.width() - 15;

      let min = slider.attr('min');
      let max = slider.attr('max');
      let percent = (val - min) / (max - min);
      let offset = -3;
      let newPosition = width * percent + offset;
      $('.current-value').css({left:newPosition});
   }

   nextLocation = () => {
      const { locations, rsValue } = this.props;
      if (locations && rsValue < locations.length - 1) {
         this.moveMarker(rsValue + 1);
      } else {
         const nextvDate = moment(this.props.date).add('1', 'days').toDate();
         this.handleDateChange(nextvDate, 'next','next')
      }

   };

   prevLocation = () => {
      const { locations, rsValue } = this.props;
      if (locations && rsValue > 0 && this.props.locations.length > 1) {
         this.moveMarker(rsValue - 1);
      } else {
         const prevDate = moment(this.props.date).subtract('1', 'days').toDate();
         this.handleDateChange(prevDate, 'prev','back')
      }

   };

   moveMarker = (index) => {
      const { locations } = this.props
      if (locations) {
         const currentLocation = locations[index]
         this.props.updateLocation({ currentLocation, rsValue: index });
      }
   }

   handleDateChange = (date, type,direction='back') => {
      const { deviceId } = this.props;
      date = new Date(date)
      let diff = moment().add('1', 'days').diff(date, 'days');
      if (diff <= 0) return true;
      if (date?.getTime()) {
         this.props.setDate(date);
         const data = { date: date.getTime(), deviceId, type,direction }
         this.props.getLocation(data);
      }
   }

   liveHandler = () => {
      const { deviceId } = this.props;
      const data = { date: new Date(), deviceId }
      this.props.recentLocation(data);

   }

   handleChange = (event) => {
      if (this.props.locations.length == 1) {
         if (event.target.value == 0) {
            const prevDate = moment(this.props.date).subtract('1', 'days').toDate();
            this.handleDateChange(prevDate, 'prev','back')
         } else {
            const nextvDate = moment(this.props.date).add('1', 'days').toDate();
            this.handleDateChange(nextvDate, 'next','next')
         }
      } else {
         const { locations } = this.props
         if (locations) {
            const currentLocation = locations[event.target.value]
            this.props.updateLocation({ currentLocation, rsValue: event.target.value });
         }
      }
   }

   render() {
      setTimeout(this.sliderPosition, 5);
      return (
         <Base title="Dashboard" page="dashboard" loader={false} devices={true}>
            <div className="main-content">
               {this.props.currentLocation && <Map />}
            </div>
            <div className="main-content-footer fixed-bottom">
               <div className="range-box">
                  <div title="Decrease" onClick={this.prevLocation} className="control-minus"><span className="fa fa-angle-left"></span></div>
                  <input
                      id="month-price"
                      type="range"
                      onChange={this.handleChange}
                      value={this.props.rsValue}
                      min={this.props.rsMin}
                      max={this.props.rsMax}
                      step={this.props.rsSteps}
                  />
                  <div className="current-value">{this.props.currentLocation.createdAt ? moment(this.props.currentLocation.createdAt).format('HH:mm') : ''}</div>
                     <div title="increase" onClick={this.nextLocation} className="control-plus"><span className="fa fa-angle-right"></span></div>
               </div>
               <div className="dg">
                  <DatePicker
                      onChange={this.handleDateChange}
                      value={this.props.date}
                      clearIcon={null}
                      maxDate={this.props.maxDate}
                      minDate={this.props.minDate}
                      className="form-control"
                      format="d MMMM y"
                      calendarIcon={<FontAwesomeIcon icon={['fas', 'calendar-alt']} size="lg" className="text-dark" />}
                  />
                  <div className="golive"><a href="#_" onClick={this.liveHandler}><img src={goLiveIcon} alt="Go Live" />
                     <span>Go Live</span> </a></div>
               </div>
            </div>
         </Base>
      )
   }
}

Dashboard.propTypes = {
   getWidgets: PropTypes.func.isRequired,
   recentLocation: PropTypes.func.isRequired,
   getLocation: PropTypes.func.isRequired,
   updateLocation: PropTypes.func.isRequired,
   setDate: PropTypes.func.isRequired,
   listDevices: PropTypes.func.isRequired,
   widgets: PropTypes.array.isRequired,
   currentLocation: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
   deviceId: state.device.deviceId,
   widgets: state.dashboard.widgets,
   currentLocation: state.dashboard.currentLocation,
   locations: state.dashboard.locations,
   date: state.dashboard.date,
   rsValue: state.dashboard.rsValue,
   rsMin: state.dashboard.rsMin,
   rsMax: state.dashboard.rsMax,
   rsSteps: state.dashboard.rsSteps,
   minDate: state.dashboard.minDate,
   maxDate: state.dashboard.maxDate,
})
export default connect(mapStateToProps, { getWidgets, recentLocation, getLocation, updateLocation, listDevices, setDate })(Dashboard);
import React, { Component } from 'react'
import { connect } from 'react-redux';

class MainWrapper extends Component {
  render() {
    return (
      <div id="wrapper">
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  status: state.sidebar.status
})

export default connect(mapStateToProps)(MainWrapper);

import React, { Component } from 'react'
import { connnect } from 'react-redux';
import logoutIconActive from '../../Asset/images/logout_icon_active.svg'

class Footer extends Component {
  render() {
    return (
        <></>
    )
  }
}

export default Footer

import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import alert from './alert';
import auth from './auth';
import device from './device';
import dashboard from './dashboard';
import sidebar from './sidebar';

const rootReducer = combineReducers({
    alert,
    auth,
    device,
    dashboard,
    sidebar,
    form: formReducer
});
export default rootReducer;
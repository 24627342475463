import React, { Component } from "react";
import Base from "../../components/layout/Base";
import comingIcon from "../../Asset/images/coming_icon.svg";
import viewIcon from "../../Asset/images/view_icon.svg";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getLocation, getWidgets, recentLocation, setDate, updateLocation} from "../../actions/dashboard";
import {listDevices} from "../../actions/device";
import geoIcon from "../../Asset/images/geo_fence_icon.svg";
import imgIcon from "../../Asset/images/img.svg";

class LocationHistory extends Component {
  componentDidMount() {
    this.props.listDevices();
  }

  state = {
    devices: [{
      _id: 1,
      name: "E8E1E10001082905",
      alias: "Car Tracker",
      eui: "E8E1E1000101363E"
    },
      {
        _id: 2,
        name: "70B3D570500016EE",
        alias: "Pet Tracker",
        eui: "70B3D570500016EE"
      }],
  }
  render() {
    return (
      <Base title="Location History" page="history">
        <div className="main-content">
          <div className="main-content-wrapper d-flex justify-content-center align-items-center position-relative">
            <div className="coming-soon text-center">
              <div className="comingsoon-circle m-auto">
                <div className="circle-inner">
                  <img src={geoIcon} alt="icon" />
                </div>
              </div>
              <h5 className="red-color text-center">Coming soon</h5>
            </div>
            <div className="soon-img"><img src={imgIcon} alt="" /></div>
          </div>
        </div>
        {/*<div className="main-content">
          <div className="main-content-wrapper">
            <div className="device-head">
              <div className="device-head-left">
                <div className="bread-nav">History</div>
              </div>
              <div className="device-head-right d-flex align-items-center"> Device
                <div className="dropdown header-dropdown ml-2">
                  <form className="form-horizontal">
                    <select onChange={this.deviceHandler}
                            className="custom-select custom-select-set form-control bg-white border-0 custom-shadow custom-radius">
                      {this.props.devices && this.props.devices.map((device, index) => {
                        return <option key={index}
                                       value={device.id}>{device.alias ? device.alias : device.name}</option>
                      })}
                    </select>
                  </form>
                </div>
              </div>
            </div>
            <div className="device-card-panel">
              <div className="main-table">
                <table id="example" className="display table device-table" style={{width:"100%"}}>
                  <thead>
                  <tr>
                    <th>Time Stamp</th>
                    <th>Loaction</th>
                    <th>Action</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td data-title="TIME STAMP :">12:45 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">12:55 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">01:05 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">01:15 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">01:55 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">02:05 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">02:15 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">02:30 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">02:55 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">03:05 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">03:15 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">03:45 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">04:05 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">04:55 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">05:05 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">05:15 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">05:45 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">05:55 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">06:05 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">06:15 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">06:45 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">06:55 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">07:05 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">07:15 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">07:45 Am</td>
                    <td data-title="LOCATION :">Pacific Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">07:55 Am</td>
                    <td data-title="LOCATION :">Quick way</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">08:05 Am</td>
                    <td data-title="LOCATION :">Parker Street</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>
                  <tr>
                    <td data-title="TIME STAMP :">01:15 Am</td>
                    <td data-title="LOCATION :">Radission blue</td>
                    <td data-title="ACTION :">
                      <div className="icon-tools">
                        <div className="show-tool">
                          <a href="dashboard.html">
                            <img src={viewIcon} alt="View icon" />
                          </a>
                        </div>
                      </div>
                    </td>

                  </tr>

                  </tbody>

                </table>
              </div>
            </div>

          </div>
        </div>*/}
      </Base>
    )
  }
}

LocationHistory.propTypes = {
  listDevices: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  deviceId: state.device.deviceId,
  devices: state.device.list
})
export default connect(mapStateToProps, {listDevices})(LocationHistory);
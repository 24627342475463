import React, { Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { renderField, renderWithoutField } from '../../components/reduxForm/Fields';
import "react-widgets/styles.css";

let Form = props => {

   const { handleSubmit, pristine, submitting, resetPassword } = props;
   return (

       <form id="resetpassword" onSubmit={handleSubmit(resetPassword)}>
          <h2 className="h2">Reset Password</h2>
          <p className="mb-4">Please create a new password</p>
          <div className="form-group">
             <Field type="number" name="otp" component={renderWithoutField} label="Enter your OTP" />
          </div>
          <div className="form-group next-field" id="">
             <Field type="password" name="newPassword" component={renderWithoutField} label="Enter new password" />
          </div>

          <div className="form-group next-field" id="">
             <Field type="password" name="cpassword" component={renderWithoutField} label="Confirm password" />
             <button type="submit" disabled={pristine || submitting} className="field-icon"><span className="fa fa-arrow-right"></span></button>
          </div>
          <div className="sat-ls-btn text-center">
              <Link to="/" id="backlog1">Back to <span className="red-color">Sign in</span></Link>
          </div>
       </form>
   )
}

Form = reduxForm({
   form: 'resetpassword',
})(Form);

export default connect(null)(Form);

import React, { Component } from "react";
import Routes from './Routes';
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { Provider } from "react-redux";

import store from "./store";

//CSS
import './assets/extra-libs/c3/c3.min.css';
import './App.css';
//CSS
import './Asset/css/style.css';
import './Asset/css/bootstrap.min.css';
import './Asset/css/bootstrap-grid.min.css';
import './Asset/css/font-awesome.min.css';
import './Asset/css/fonts.css';
import './Asset/css/responsive.css';
import {Toaster} from "react-hot-toast";

library.add(fab, fas, far);

class App extends Component {

   componentDidMount() {
      if (localStorage.token) {
         setAuthToken(localStorage.token);
      }
      store.dispatch(loadUser());
   }

   render() {
      return <Provider store={store}>
         <Routes />
         <Toaster
             position="bottom-center"
             reverseOrder={false} />
      </Provider>
   }
}

export default App;
import {
    SIDEBAR,
} from "./types";

// Toggle Sidebar
export const toggleSidebar = (status) => async (dispatch) => {
    dispatch({
        type: SIDEBAR,
        payload: status
    });
};
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import {listDevices, updateDeviceIcon} from '../../actions/device';
import trackerIcon from '../../assets/images/trackerIcon.png'
import editIcon from '../../Asset/images/edit_icon.svg'
import viewIcon from '../../Asset/images/view_icon.svg'
import { deviceDetails, updateDevice } from "../../actions/device";
import Base from '../../components/layout/Base';
import { APIURL } from '../../actions/config';
import './style.css';
import {Modal} from 'react-bootstrap';
import {Field, SubmissionError} from "redux-form";
import Form from "./Form";
import {renderWithValue} from "../../components/reduxForm/Fields";

class List extends Component {

   constructor(props) {
      super(props);

   }
   componentDidMount() {
      this.props.listDevices();
   }

   state = {
      devices: [{
         _id: 1,
         name: "E8E1E10001082905",
         alias: "Car Tracker",
         eui: "E8E1E1000101363E"
      },
      {
         _id: 2,
         name: "70B3D570500016EE",
         alias: "Pet Tracker",
         eui: "70B3D570500016EE"
      }],
      updateShow:false,
      detailShow:false,
      currentDeviceData:[],
      alias:'',
      trackerIcon:''
   }

   showUpdatePopup(data){
      this.setState({
         updateShow:true,
         currentDeviceData:data,
         alias:data.alias,
         trackerIcon : data.icon ? `${APIURL}/uploads/${data.icon}` : trackerIcon
      })
   }

   updateIcon(event) {
      const file = event.target.files[0];
      this.setState({
         trackerIcon:URL.createObjectURL(file),
      })
      const formData = new FormData();
      formData.append("icon", file);
      formData.append("deviceId", this.state.currentDeviceData.id);
      this.props.updateDeviceIcon(formData);
   }

   showDetailPopup(data) {
      this.setState({
         detailShow:true,
         currentDeviceData:data
      })
   }
   handleClose = () => {
      this.setState({
         updateShow:false,
         detailShow:false,
         currentDeviceData:[]
      })
   }

   formHandler = async (event) => {
      event.preventDefault();
      const formData = {
         id:this.state.currentDeviceData.id,
         alias:this.state.alias
      }
      if (Object.keys(formData).length) {
         ['alias'].forEach(field => {
            if (!formData[field]) {
               throw new SubmissionError({
                  [field]: `This field is required`,
                  _error: 'Failed to add user'
               })
            }
         });
        await this.props.updateDevice(formData);
         this.handleClose();
         this.props.listDevices();
      } else {
         throw new SubmissionError({
            Alias: 'Alias is required',
            _error: 'Failed to add user'
         })
      }
   }
   handleAliasChange= e => {
      this.setState({alias: e.target.value});
   }

   formReset = () => {
      this.setState({alias: this.state.currentDeviceData.alias});
   }


   render() {
      return (
         <Base title="Devices" page="devices">
            {/* <Alert timeout={2000} /> */}
            <div className="main-content">
               <div className="main-content-wrapper">
                  <div className="device-head">
                     <div className="device-head-left">
                        <div className="bread-nav">Devices List</div>
                     </div>
                  </div>
                  <div className="device-card-panel">
                     <div className="main-table">
                        <table className="table device-table">
                           <thead>
                           <tr>
                              <th scope="col">#</th>
                              <th scope="col">DEVICE NAME</th>
                              <th scope="col">DEVICE ALIAS</th>
                              <th scope="col">DEVICE EUI</th>
                              <th scope="col">DEVICE ICON</th>
                              <th scope="col">ACTION</th>
                           </tr>
                           </thead>
                           <tbody>
                           {this.props.devices && this.props.devices.map((device, index) => {
                              return <tr key={index}>
                                 <td data-title="" className="dnone" scope="row"> {index + 1}</td>
                                 <td data-title="DEVICE NAME :">{device.name}</td>
                                 <td data-title="DEVICE ALIAS :">{device.alias}</td>
                                 <td data-title="DEVICE EUI :">{device.eui}</td>
                                 <td data-title="DEVICE ICON :">
                                    <div className="device-icon"><img src={device.icon ? `${APIURL}/uploads/${device.icon}` : trackerIcon} alt="icon" /></div>
                                 </td>
                                 <td data-title="ACTION :">
                                    <div className="icon-tools">
                                       <div className="edit-tool">
                                          <a href="#_" onClick={() => this.showUpdatePopup(device)}>
                                             <img src={editIcon} alt="edit icon" />
                                          </a>
                                       </div>
                                       <div className="show-tool">
                                          <a href="#_" onClick={() => this.showDetailPopup(device)}>
                                             <img src={viewIcon} alt="View icon" />
                                          </a>
                                       </div>
                                    </div>
                                 </td>
                              </tr>
                           })}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <Modal show={this.state.updateShow} onHide={this.handleClose}>
               <Modal.Header closeButton>
                  <h4 className="modal-title">Update Device</h4>
                  <button type="button" className="close" data-dismiss="modal" onClick={this.handleClose}><i className="fa fa-close"></i>
                  </button>
               </Modal.Header>
               <Modal.Body>
                  <form onSubmit={(event) => this.formHandler(event)}>
                     <div className="form-group">
                        <label htmlFor="devicename">Device Name:</label>
                        <input type="text" name="name" value={this.state.currentDeviceData.name} className="form-control" readOnly={true} />
                     </div>
                     <div className="form-group">
                        <label htmlFor="devicealias">Device Alias:</label>
                        <input type="text" name="alias" value={this.state.alias} className="form-control" onChange={this.handleAliasChange} />
                     </div>
                     <div className="form-group">
                        <label htmlFor="deviceeui">Device EUI:</label>
                        <input type="text" name="eui" value={this.state.currentDeviceData.eui} className="form-control" readOnly={true} />
                     </div>
                     <div className="form-group fileupload">
                        <label htmlFor="name" className="compu_n mr-3">Device Icon</label>
                        <div className="device-detail-left mr-3">
                           <img src={this.state.trackerIcon} alt="device icon" />
                        </div>
                        <div className="file-uploader">
                           <div className="file-upload-btn mt-0"><i
                               className="fa fa-cloud-upload"></i> Upload <input
                               type="file" name="file" onChange={event => this.updateIcon(event)} /></div>
                        </div>
                     </div>
                     <div className="form-actions">
                        <div className="text-center mt-4">
                           <button type="submit" className="btn border-btn mr-2">Submit</button>
                        </div>
                     </div>
                  </form>
               </Modal.Body>
            </Modal>

            <Modal show={this.state.detailShow} onHide={this.handleClose}>
               <Modal.Header closeButton>
                  <button type="button" className="close" data-dismiss="modal"  onClick={this.handleClose}><i className="fa fa-close"></i>
                  </button>
               </Modal.Header>
               <Modal.Body>
                  <div className="device-detail">
                     <div className="device-detail-left">
                        <img src={this.state.currentDeviceData.icon ? `${APIURL}/uploads/${this.state.currentDeviceData.icon}` : trackerIcon} alt="device icon" />
                     </div>
                     <div className="device-detail-right">
                        <ul>
                           <li>Name <span>: {this.state.currentDeviceData.name}</span></li>
                           <li>Alias <span>: {this.state.currentDeviceData.alias}</span></li>
                           <li>EUI <span>: {this.state.currentDeviceData.eui}</span></li>
                        </ul>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </Base>
      )
   }

}

List.propTypes = {
   listDevices: PropTypes.func.isRequired,
   updateDevice: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
   devices: state.device.list
})

export default connect(mapStateToProps, { listDevices,updateDevice,updateDeviceIcon })(List);

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { updateDeviceIcon } from "../../actions/device";
import {renderWithValue} from '../../components/reduxForm/Fields';
import "react-widgets/styles.css";

let Form = props => {

    const updateIcon = event => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("icon", file);
        formData.append("deviceId", props.device.id);
        props.updateDeviceIcon(formData);
    }

    const { handleSubmit, pristine, submitting, reset, submitDevice, deviceData } = props;

    const changeAlias = event => {
        console.log(event.target.value);
        deviceData.alias = event.target.value;
    }
    return (
        <form onSubmit={handleSubmit(submitDevice)}>
            <div className="form-group">
                <Field type="text" name="name" component={renderWithValue} label={deviceData.name} readOnly={true} />
            </div>
            <div className="form-group">
                <Field type="text" name="alias" onChange={changeAlias} component={renderWithValue} label={deviceData.alias} readOnly={false} />
            </div>
            <div className="form-group">
                <Field type="text" name="eui" component={renderWithValue} label={deviceData.eui} readOnly={true}/>
            </div>
            <div className="form-group fileupload">
                <label htmlFor="name" className="compu_n mr-3">Device Icon</label>
                <div className="file-uploader">
                    <div className="file-upload-btn mt-0"><i
                        className="fa fa-cloud-upload"></i> Upload <input
                        type="file" name="file" onChange={updateIcon} /></div>
                </div>
            </div>
            <div className="form-actions">
                <div className="text-right">
                    <button type="submit" className="btn btn-info mr-2">Submit</button>
                    <button type="button" disabled={pristine || submitting} onClick={reset} className="btn btn-dark">Reset</button>
                </div>
            </div>
        </form>
    )
}

Form = reduxForm({
    form: 'device',
    enableReinitialize: true
})(Form);

const mapStateToProps = state => ({
    initialValues: state.device.details,
    device: state.device.details,
})
export default connect(mapStateToProps, { updateDeviceIcon })(Form);

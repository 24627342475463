import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { renderWithoutField } from '../../components/reduxForm/Fields';
import "react-widgets/styles.css";

let Form = props => {

   const { handleSubmit, pristine, submitting, submitForgot } = props;
   return (

       <form id="forgotform" onSubmit={handleSubmit(submitForgot)}>
          <h2 className="h2">Forgot Password</h2>
          <p className="mb-4">Enter your email address and we will send you a link to reset your password.</p>
          <div className="form-group">
             <Field type="text" name="email" component={renderWithoutField} label="Enter your email" />
             <button type="submit" disabled={pristine || submitting} className="field-icon" id="nextverify"><span className="fa fa-arrow-right"></span>
             </button>
          </div>
          <div className="sat-ls-btn text-center">
              <Link to="/" id="backlog1">Back to <span className="red-color">Sign in</span></Link>
          </div>
       </form>
   )
}

Form = reduxForm({
   form: 'forgot',
})(Form);

export default connect(null)(Form);
